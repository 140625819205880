import store from "../store/store";
import axios from "@/axios";
import { handleProductDetailRedirect, handleBeforeEnterPermissionRedirect } from "../utils/navigationsGuard";

export default [
  {
    path: "/nurse",
    redirect: process.env.VUE_APP_REGION === "AU" ? "/nurse/home" : "/nurse/notice-board",
    component: () => import("@/layouts/main/nurse/NurseMain.vue"),
    meta: {
      rule: "editor",
    },
    // path: "/nurse",
    // redirect: "/nurse/notice-board",
    // meta: {
    //   rule: "editor",
    // },
    // component: () => import("@/layouts/main/nurse/NurseMain.vue"),
    children: [
      // {
      //   path: "register",
      //   name: "DoctorRegister",
      //   component: () => import("@/views/doctor/DoctorRegister.vue"),
      //   meta: {
      //     pageTitle: "Doctor Register",
      //     rule: "editor"
      //   }
      // },
      {
        path: "notice-board",
        name: "notice-board",
        component: () => import("@/views/basic/Dashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | My Organisations",
          rule: "editor",
        },
      },
      {
        path: "select-workspace",
        name: "select-workspace",
        component: () =>
          import("@/views/pages/select-organization/SelectOrganization.vue"),
        meta: {
          pageTitle: "Fresh Clinics | My Organisations",
          rule: "editor",
        },
      },
      {
        path: "events",
        name: "events",
        component: () => import("@/views/basic/Events.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Events",
          rule: "editor",
        },
      },
      {
        path: "supplier-training",
        name: "supplier-training",
        component: () => import("@/views/basic/SupplierTraining.vue"),
        meta: {
        pageTitle: "Fresh Clinics | Supplier Training",
        rule: "editor",
        },
      },
      {
        path: "referral",
        name: "referral",
        component: () => import("@/views/pages/referral/referralPage.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/home" },
          //   { title: "Refer Someone to Fresh", active: true },
          // ],
          pageTitle: "Fresh Clinics | Refer a Friend",
          rule: "editor",
        },
      },
      {
        path: "resources",
        name: "resources",
        component: () => import("@/views/nurse/Resources.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/search",
        name: "nurse-search-resources",
        component: () => import("@/views/nurse/ResourceSearch.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/:topic",
        name: "nurse-topic-resources",
        component: () => import("@/views/nurse/ResourceTopic.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/:topic/:brand",
        name: "nurse-partner-resources",
        component: () => import("@/views/nurse/ResourcePartner.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "financial-assistance",
        name: "financial-assistance",
        component: () => import("@/views/general-component/FinancialAssistance.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Financial Assistance",
          rule: "editor",
        },
      },
      /** CONSENT FORM ROUTE STARTED */
      {
        path: "consent-forms",
        name: "NurseConsentForms",
        component: () => import("@/views/general-component/consent-forms/ConsentForms.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      {
        path: "consent-forms/:consentId/details",
        name: "NurseConsentFormsDetails",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      {
        path: "consent-forms/create",
        name: "NurseConsentFormsCreate",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      /** CONSENT FORM ROUTE ENDED */
      {
        path: "dashboard",
        name: "NurseDashboard",
        component: () => import("@/views/nurse/NurseDashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Dashboard",
          rule: "editor",
        },
      },
      {
        path: "change-password",
        name: "NurseChangePassword",
        component: () => import("@/views/pages/changePassword.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "Change Password", active: true },
          ],
          pageTitle: "Fresh Clinics | Change Password",
          rule: "editor",
        },
      },
      {
        path: "success",
        name: "nurse-change-password-success",
        component: () => import("@/views/pages/Success.vue"),
        meta: {
          rule: "editor",
        },
      },
      // {
      //   path: "clinics",
      //   name: "NurseClinic",
      //   component: () => import("@/views/nurse/clinics/NurseClinics.vue"),
      //   meta: {
      //     breadcrumb: [
      //       { title: "Home", url: "nurse/dashboard" },
      //       { title: "List", active: true },
      //     ],
      //     pageTitle: "Fresh Clinics | Clinics | List",
      //     rule: "editor",
      //   },
      // },
      // {
      //   path: "clinics/:clinicId/detail",
      //   name: "NurseClinicDetail",
      //   component: () =>
      //     import("@/views/nurse/clinics/NurseClinicDetail.vue"),
      //   meta: {
      //     breadcrumb: [
      //       { title: "Home", url: "nurse/dashboard" },
      //       { title: "Clinics", url: "/nurse/clinics" },
      //       { title: "Detail", active: true },
      //     ],
      //     pageTitle: "Fresh Clinics | Clinics | Detail",
      //     rule: "editor",
      //   },
      // },
      {
        path: "clinics",
        name: "NurseClinics",
        component: () => import("@/views/org-owner/clinics/list.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Clinics",
          rule: "editor",
        },
      },
      {
        path: "clinic/:clinicId/edit",
        name: "NurseEditClinic",
        component: () => import("@/views/org-owner/clinics/editClinic.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/dashboard" },
          //   { title: "Invoices", active: true },
          // ],
          pageTitle: "Fresh Clinics | Org Owner | Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "profile",
        name: "NurseProfileDetail",
        component: () => import("@/views/nurse/NurseProfileDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "Profile", active: true },
          ],
          pageTitle: "Fresh Clinics | Profile",
          rule: "editor",
        },
      },
      {
        path: "patients",
        name: "NursePatient",
        component: () => import("@/views/nurse/patients/NursePatient.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | List",
          rule: "editor",
        },
      },
      {
        path: "patients/:patientId/details",
        name: "NursePatientDetail",
        component: () =>
          import("@/views/nurse/patients/NursePatientDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "Patients", url: "/nurse/patients" },
            { title: "Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | Detail",
          rule: "editor",
        },
      },
      {
        path: "patients/:patientId/treatment/:checkinId",
        name: "NursePatientTreatmentDetail",
        component: () =>
          import("@/views/nurse/patients/PatientTreatmentDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "Patients", url: "/nurse/patients" },
            { title: "Treatment Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | Treatment Detail",
          rule: "editor",
        },
      },
      /**Nurse Notification Route Started**/
      {
        path: "notifications",
        name: "nurseNotification",
        component: () =>
          import("@/views/nurse/notifications/notifications.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Notifications | List",
          rule: "editor",
        },
      },
      /**Nurse Notification Route Ended**/
      /**Nurse POS Route Started**/
      {
        path: "pos",
        name: "nursePOS",
        component: () =>
          import("@/views/nurse/pos/nursePos.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "nurse/dashboard" },
          //   { title: "List", active: true },
          // ],
          pageTitle: "Fresh Clinics | POS",
          rule: "editor",
        },
      },
      /**Nurse POS Route Ended**/
      /**Nurse Treatment History Route Started**/
      {
        path: "treatment-history",
        name: "NurseTreatmentHistory",
        component: () => import("@/views/nurse/history/TreatmentHistory.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Treatment History | List",
          rule: "editor",
        },
      },
      /**Nurse Treatment History Route Ended**/
      /** Nurse Shop */
      {
        path: "shop",
        name: "NurseOrderCenter",
        component: () =>
          import("@/views/org-owner/ecommerce/OrderCenter.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/shop" },
            { title: "Products", active: true },
          ],
          pageTitle: "Fresh Clinics | Products",
          rule: "editor",
          permission:'Shop'
        },
      },
      {
        path: "shop/category/:slug",
        name: "NurseOrderCenterCategoryPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Category.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      // {
      //   path: "shop/category_/:slug",
      //   name: "NurseOrderCenterCategory_Page",
      //   component: () =>
      //     import("@/views/org-owner/ecommerce/SlugCategory.vue"),
      //   meta: {
      //     pageTitle: "Fresh Clinics | Shop",
      //     rule: "editor",
      //   },
      // },
      {
        path: "shop/promotions",
        name: "NurseOrderCenterPromotionsPage",
        component: () =>
          import("@/views/org-owner/ecommerce/ShopPromotions.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/fresh-life",
        name: "NurseOrderCenterFreshLifePage",
        component: () =>
          import("@/views/org-owner/ecommerce/FreshlifePromotion.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Shop | Fresh Life Promos",
          rule: "editor",
        },
      },
      {
        path: "shop/search",
        name: "NurseOrderCenterSearch",
        component: () =>
          import("@/views/org-owner/ecommerce/SearchResult.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/brands/:slug",
        name: "NurseOrderCenterBrandPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Brand.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "orders",
        name: "NurseOrderList",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/shop" },
            { title: "Orders", active: true },
          ],
          pageTitle: "Fresh Clinics | Orders",
          rule: "editor",
          permission:'Shop'
        },
      },
      {
        path: "product/:id",
        name: "NurseProductDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/ProductDetail.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Product Detail",
          rule: "editor",
          permission:'Shop'
        },
        beforeEnter: handleProductDetailRedirect,
      },
      {
        path: "checkout",
        name: "NurseProductCheckout",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicCheckout.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/shop" },
            { title: "Products", url: "/nurse/shop" },
            { title: "Product Checkout", active: true },
          ],
          pageTitle: "Fresh Clinics | Product Checkout",
          rule: "editor",
          permission:'Shop'
        },
      },
      {
        path: "order-complete",
        name: "NurseOrderComplete",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicOrderComplete.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/shop" },
            { title: "Products", url: "/nurse/shop" },
            { title: "Order Complete", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Complete",
          rule: "editor",
          permission:'Shop'
        },
      },
      {
        path: "orders",
        name: "NurseOrderList",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/shop" },
            { title: "Orders", active: true },
          ],
          pageTitle: "Fresh Clinics | Orders",
          rule: "editor",
          permission:'Shop'
        },
      },
      {
        path: "orders/:orderId",
        name: "NurseOrderDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/shop" },
            { title: "Orders", url: "/nurse/orders" },
            { title: "Order Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Detail",
          rule: "editor",
          permission:'Shop'
        },
      },
      {
        path: "drugbook/setup",
        name: "NurseDrugbook",
        component: () =>
          import("@/views/org-owner/drug-book/SetUpDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
          permission:'Drug'
        },
      },
      {
        path: "drugbook/setup/clinic",
        name: "NurseDrugbookTransfer",
        component: () =>
          import("@/views/org-owner/drug-book/TransferClinicDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Set up",
          rule: "editor",
          permission:'Drug'
        },
      },
      {
        path: "drugbook/entries",
        name: "NurseDrugbookEntries",
        component: () =>
          import("@/views/org-owner/drug-book/drugbookEntries.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Outstanding",
          rule: "editor",
          permission:'Drug'
        },
      },
      {
        path: "drugbook",
        name: "NurseDrugbookLanding",
        component: () =>
          import("@/views/org-owner/drug-book/Drugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
          permission: "Drug"
        },
      },
      {
        path: "inventory",
        name: "NurseInventory",
        component: () => import("@/views/org-owner/inventory/Inventory.vue"),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/nurse/clinics"},
            {title: "Inventory", active: true }
          ],
          pageTitle: "Fresh Clinics | Insight - Inventory",
          rule: "editor",
        },
      },
      {
        path: "invoices",
        name: "Nurseinvoices",
        component: () => import("@/views/organization/invoices/List.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Invoices", active: true },
          ],
          pageTitle: "Fresh Clinics | Invoices",
          rule: "editor",
        },
      },
      {
        path: "staff",
        name: "Nursestaff",
        component: () => import("@/views/organization/users/index.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/staff" },
            { title: "Staff", active: true },
          ],
          pageTitle: "Fresh Clinics | Staff",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          handleBeforeEnterPermissionRedirect(to, from, next, "Staff", "home")
        }
      },
      {
        path: "clinics/:clinicId/edit",
        name: "OrgOwnerClinicEdit",
        component: () => import("@/views/admin/clinics/clinicsEdit.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Clinics", url: "/nurse/clinics" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "basic-user/:basicUserId",
        name: "NurseBasicEditRoute",
        component: () =>
          import("@/views/org-owner/basic-user/BasicDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Basic" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Basic | Edit",
          rule: "editor",
        },
      },
      {
        path: "nurses/:nurseId",
        name: "NurseNurseDetails",
        component: () => import("@/views/org-owner/nurses/NurseDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Nurses", url: "/nurse/nurses" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Nurses | Edit",
          rule: "editor",
        },
      },
      {
        path: "doctors/:doctorId/edit",
        name: "NurseDoctorEdit",
        component: () => import("@/views/org-owner/doctors/doctorEdit.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Doctors", url: "/nurse/doctors" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Doctors | Edit",
          rule: "editor",
        },
      },
      {
        path: "doctor-clinics/:franchiseId",
        name: "NurseDoctorClinicsEdit",
        component: () =>
          import("@/views/org-owner/doctor-clinics/viewFranchise.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Doctor Clinics", url: "/nurse/doctor-clinics" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Doctor Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "derma-specialist/:franchiseId",
        name: "NurseDermaSpecialistEdit",
        component: () =>
          import("@/views/org-owner/derma-specialist/viewFranchise.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            {
              title: "Derma Specialist",
              url: "/nurse/derma-specialist",
            },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Derma Specialist | Edit",
          rule: "editor",
        },
      },
      {
        path: "settings/treatment-areas",
        name: "NurseTreatmentAreas",
        component: () =>
          import("@/views/franchise/settings/TreatmentArea.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
      {
        path: "settings/products-list",
        name: "NurseProductsList",
        component: () =>
          import("@/views/franchise/settings/ProductsList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
      /** Custom Product ROUTE START */
      {
        path: "settings/custom-products-list",
        name: "NurseCustomProductsList",
        component: () =>
          import("@/views/franchise/settings/CustomProductsList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      {
        path: "settings/custom-products-list/create",
        name: "NurseCustomProductsListCreate",
        component: () =>
          import("@/views/components/org-owner/custom-products/AddCustomProduct.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products | Create",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      {
        path: "settings/custom-products-list/:productId/edit",
        name: "NurseCustomProductsListDetails",
        component: () =>
          import("@/views/components/org-owner/custom-products/EditCustomProduct.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products | Edit",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      /** Custom Product ROUTE END */
      {
        path: "compliance",
        name: "NurseCompliance",
        component: () =>
          import("@/views/org-owner/compliance/Compliance.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/review",
        name: "NurseComplianceReview",
        component: () => import("@/views/org-owner/compliance/Review.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Compliance", url: "/nurse/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/step/image/upload",
        name: "NurseComplianceStepImageUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Compliance", url: "/nurse/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/upload",
        name: "NurseComplianceStepUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceVideoUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/nurse/clinics" },
            { title: "Compliance", url: "/nurse/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      /**Nurse Shop Route Ended**/
      /**INSIGHTS CLINIC DASHBOARD ROUTE */
      {
        path: "home",
        name: "nurse-dashboard",
        component: () => import("@/views/superAdmin/clinic-nurse-dashboard/ClinicNurseDashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Home",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          try {
            const organizationId = localStorage.getItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
            );
            const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
            const authHeader = {
              Authorization: token
            }

            if (organizationId) {
              const { data: orgData } = await axios.get(
                `/api/v1/organization/${organizationId}`,
                {
                  headers: authHeader,
                }
              );

              if (!orgData.data.isAnalyticsEnabled) {
                next({ path: "/nurse/notice-board" });
              } else {
                next();
              }
            }

            next();
          } catch (error) {
            console.log(`Error fetching: ${error}`)
            next({ path: from.path });
          }
        }
      },
      /**INSIGHTS ROUTE STARTED */
      {
        path: "insights",
        name: "NurseClinicDashboard",
        component: () => import("@/views/superAdmin/clinic-dashboard-v2/ClinicDashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Insights",
          rule: "editor"
        },
        beforeEnter: async (to, from, next) => {
          try {
            const userId = store.state.AppActiveUser.id;
            const organizationId = localStorage.getItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
            );
            const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
            const authHeader = {
              Authorization: token
            }

            const { data: orgData } = await axios.get(`/api/v1/organization/${organizationId}`, {
              headers: authHeader
            })
            const { data: permissionData } = await axios.get(`/api/v1/admins/user-permission?userId=${userId}&organizationId=${organizationId}`, {
              headers: authHeader
            })

            const hasPermission = permissionData
              .filter((item) => item.permission.name === "Insights");

            if (hasPermission.length && orgData.data.isAnalyticsEnabled) {
              next();
            } else {
              next({ path: "/notice-board" });
            }
          } catch (error) {
            console.log(`Error fetching: ${error}`)
            next({ path: from.path });
          }
        },
      },
    ],
  },
]
